<template>
    <div class="update_password">
        <van-nav-bar
      title="修改密码"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
        <van-form @submit="onSubmit">
            <van-field
                class="old_password"
                v-model="oldPassword"
                type="password"
                name="原密码"
                label="原密码"
                placeholder="请输入原密码"
            />
            <van-field
                class="new_password"
                v-model="newPassword"
                type="password"
                name="新密码"
                label="新密码"
                placeholder="请输入新密码"
            />
            <van-field
                class="confirm_password"
                v-model="confirmPassword"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="请输入确认密码"
            />
            <div style="margin:30px 16px 16px 16px" class="update_password_btn">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { ChangePageTitle } from "../../common/js/upTitle"
import {updatePassword} from '@/api/login'
import {mapActions} from 'vuex'
export default {
    data() {
        return {
            oldPassword: "", //原密码
            newPassword: "", //新密码
            confirmPassword: "", //第二次输入新密码
        };
    },
    created() {
        ChangePageTitle("修改密码");
    },
    methods: {
        ...mapActions(['user/bowOut']),
        onClickLeft(){
            this.$router.push("/user");
        },
        async onSubmit() {
            try{if(this.oldPassword == '' || this.newPassword == ''  || this.confirmPassword == '' ){
                this.$toast('请输入');
                return
            }
            const params = {
                oldPassword : this.oldPassword,
                newPassword : this.newPassword,
                confirmPassword : this.confirmPassword
            }
            await updatePassword(params);
            this.$toast.success('修改成功，请重新登录')
            this['user/bowOut']();
            this.$router.push('/login')
            }catch(err){
                console.log(err)
            }
        },
    },
};
</script>
<style lang="less" scoped>
.update_password {
    width: 100vw;
    height: 100vh;
    background-color: #FAFAFA;
    overflow: hidden;
    .van-form{
        width: 100vw;
        margin-top: 10vh;
        .old_password{
            width: 95%;
            margin: auto;
            border-radius: 10px;
        }
        .new_password{
            width: 95%;
            margin: auto;
            border-radius: 10px;
            margin-top: 20px;
        }
        .confirm_password{
            width: 95%;
            margin: auto;
            border-radius: 10px;
            margin-top: 20px;
        }
    
    }
}
</style>